'use client'

import { cn } from '@paladise/ui/lib/utils'
import { useRouter, useSearchParams } from 'next/navigation'
import { useDevice } from 'store/provider/device-provider'
import HamburgerMenu from './HamburgerMenu'
import { ROUTE_PATH } from 'config/routerPath'

function HamburgerHeader() {
  const { push } = useRouter()
  const searchParams = useSearchParams()
  const device = useDevice()

  const isEmbed = searchParams.get('isEmbed') === 'true'
  const isMobile = device === 'mobile'

  if (isEmbed) return null
  return (
    <div
      className={cn(
        'h-mobile-fixed-top min768:h-fixed-top fixed left-0 top-0 z-20 flex w-full items-center justify-between bg-white px-12 py-3',
        isMobile && 'px-4',
      )}
    >
      {isMobile && <HamburgerMenu />}

      <img
        src="/images/logo.svg"
        alt="PalUp"
        className={cn(
          '!h-[33px] !w-[80px] cursor-pointer',
          isMobile &&
            'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
        )}
        role="img"
        aria-label="Logo"
        onClick={() => {
          push(ROUTE_PATH.SIDEBAR.FEED)
        }}
      />
    </div>
  )
}

export default HamburgerHeader
